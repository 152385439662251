import { Box, CardMedia, IconButton } from '@mui/material';
import React, { useState, useCallback } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useInView } from 'react-intersection-observer';
import { CarouselProps } from 'react-material-ui-carousel/dist/components/types';
import { userImgUrl } from 'utilities/userImageURL';
import CustomVideoPlayer from './customVideoPlayer';
import {
  ZoomShrink,
  ZoomExpand,
  ZoomIn,
  ZoomOut,
  ZoomReset
} from 'assets/icon';
import {
  TransformWrapper,
  TransformComponent,
  useControls
} from "react-zoom-pan-pinch";

export type IMediaItems = {
  fileName: string;
  directUrl: string;
  [key: string]: any;
};

interface Props extends CarouselProps {
  mediaDtos: IMediaItems[];
  scrolling?: boolean;
  activeIndex?: number;
  setActiveIndex?: (value: number) => void;
  setFullScreen?: (value: boolean) => void;
  setMediaItems?: (mediaItems: IMediaItems[]) => void;
  setVideoMedia?: (value: boolean) => void;
  imgSx?: React.CSSProperties;
  videoSx?: React.CSSProperties;
  fullScreenView?: boolean;
  enableZoom?: boolean;
}

const LazyMedia = React.memo(
  ({
    src,
    isVideo,
    imgSx,
    videoSx,
    pauseScrollChange,
    pauseVideoChange,
    preload = false,
    enableZoom = false
  }: {
    src: string;
    isVideo: boolean;
    imgSx?: React.CSSProperties;
    videoSx?: React.CSSProperties;
    pauseScrollChange: boolean;
    pauseVideoChange: boolean;
    preload?: boolean;
    enableZoom?: boolean;
  }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
      rootMargin: '1000px'
    });
    const isVisible = inView || preload;

    React.useEffect(() => {
      if (!isVideo && isVisible) {
        const img = new Image();
        img.src = src;
      }
    }, [isVisible, isVideo, src]);
    const [expand, setExpand] = useState(false);

    const Controls = () => {
      const { zoomIn, zoomOut, resetTransform, setTransform } = useControls();
      return (
        <div
            style={{
              position: 'fixed',
              bottom: '30px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              display: 'flex',
              padding: '10px',
              width: '169px',
              height: '44px',
              borderRadius: '4px',
              backdropFilter: 'blur(4px)',
              background: '#0000000D',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <IconButton
              title="Zoom in"
              onClick={() => zoomIn()}
              style={zoomButtonStyle}
              sx={{ ':hover': { backgroundColor: 'rgb(0,0,0,0)' } }}
            >
              <ZoomIn />
            </IconButton>
            <IconButton
              title="Zoom out"
              onClick={() => zoomOut()}
              style={zoomButtonStyle}
              sx={{ ':hover': { backgroundColor: 'rgb(0,0,0,0)' } }}
            >
              <ZoomOut />
            </IconButton>
            <IconButton title="Default"
              onClick={() => {
                setExpand(false);
                resetTransform();
              }}
              style={zoomButtonStyle}
              sx={{ ':hover': { backgroundColor: 'rgb(0,0,0,0)' } }}
            >
              <ZoomReset />
            </IconButton>
            {expand ? (
              <IconButton
                title="Collapse"
                onClick={() => {
                  setExpand(false);
                  resetTransform();
                }}
                style={zoomButtonStyle}
                sx={{ ':hover': { backgroundColor: 'rgb(0,0,0,0)' } }}
              >
                <ZoomShrink />
              </IconButton>
            ) : (
              <IconButton
                title="Full Screen"
                onClick={() => {
                  setExpand(true);
                  setTransform(-window.innerWidth, -window.innerHeight, 3);
                }}
                style={zoomButtonStyle}
                sx={{ ':hover': { backgroundColor: 'rgb(0,0,0,0)' } }}
              >
                <ZoomExpand />
              </IconButton>
            )}
        </div>
      );
    };

    const cardMedia = () => {
      return (
        !isVideo ? (
        <>
          <CardMedia
            component='img'
            image={isVisible ? src : ''}
            loading='lazy'
            sx={{
              width: '100%',
              height: imgSx?.height ?? '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 1,
              opacity: 0.3,
              transform: 'scale(1.2)',
              filter: 'blur(24px)',
              transition: 'opacity 0.3s ease-in-out'
            }}
          />

          <CardMedia
            component='img'
            image={isVisible ? src : ''}
            loading='lazy'
            sx={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              objectFit: 'contain',
              position: 'relative',
              zIndex: 2,
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              ...imgSx
            }}
          />
        </>
         ) : (
          <CustomVideoPlayer
            url={isVisible ? src : ''}
            pauseVideoChange={pauseVideoChange}
            pauseScrollChange={pauseScrollChange}
            videoSx={videoSx}
          />
        )
      )
    }
    return (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          visibility: preload ? 'hidden' : 'visible'
        }}
      >
        {/* Zoom Controls (Visible only if enableZoom is true) */
          enableZoom ? (
            <TransformWrapper
              disablePadding={true}
              disabled={!enableZoom}
              wheel={{
                disabled: !enableZoom,
                wheelDisabled: !enableZoom,
                touchPadDisabled: !enableZoom
              }}
              panning= {{
                disabled: !enableZoom,
                velocityDisabled: !enableZoom
              }}
            >
              {enableZoom && (<Controls />)}
              <TransformComponent wrapperStyle={{width: '100%', height: '100%'}} contentStyle={{width: '100%', height: '100%'}}>
                {cardMedia()}
              </TransformComponent>
            </TransformWrapper>
          ) : (
            cardMedia()
          )}
      </Box>
    );
  }
);

const CarousalItems = ({
  mediaDtos,
  scrolling = false,
  activeIndex,
  setActiveIndex,
  setFullScreen,
  setMediaItems,
  setVideoMedia,
  imgSx,
  videoSx,
  fullScreenView,
  enableZoom,
  ...carouselProps
}: Props) => {
  const [activePlay, setActivePlay] = useState<number>(
    fullScreenView ? activeIndex || 0 : 0
  );

  const isVideo = useCallback(
    (fileName: string) =>
      ['.mp4', '.mov'].some((ext) => fileName.toLowerCase().endsWith(ext)),
    []
  );

  const getImageUrl = useCallback(
    (item: IMediaItems) => item.directUrl || userImgUrl(item.id),
    []
  );

  const handleSelect = useCallback((now?: number) => {
    if (now !== undefined) {
      setActivePlay(now);
    }
  }, []);

  return (
    <Carousel
      onChange={handleSelect}
      index={activeIndex}
      navButtonsAlwaysVisible={mediaDtos.length > 1}
      indicators={mediaDtos.length > 1 && !fullScreenView}
      cycleNavigation={false}
      autoPlay={false}
      animation={mediaDtos.length > 2 ? 'slide' : undefined}
      navButtonsWrapperProps={{ style: { top: '45%', height: 'auto' } }}
      swipe={false}
      {...carouselProps}
    >
      {mediaDtos.map((item, index) => {
        const mediaUrl = getImageUrl(item);
        const isMediaVideo = isVideo(item.fileName);
        const isNextItem = activePlay + 1 === index || activePlay - 1 === index;
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              height: fullScreenView ? 'auto' : '400px',
              cursor: 'pointer',
              ...(mediaDtos.length === 2 && {
                transform: `translateX(${activePlay === index ? '0%' : index === 0 ? '-100%' : '100%'})`,
                transition: 'transform 0.5s ease-in-out'
              })
            }}
            onClick={(e) => {
              e.stopPropagation();
              setFullScreen?.(true);
              setMediaItems?.(mediaDtos);
              setActiveIndex?.(index);
              setVideoMedia?.(isMediaVideo);
            }}
          >
            <LazyMedia
              src={mediaUrl}
              isVideo={isMediaVideo}
              imgSx={imgSx}
              videoSx={videoSx}
              pauseVideoChange={activePlay === index}
              pauseScrollChange={scrolling}
              preload={isNextItem}
              enableZoom={enableZoom}
            />
          </Box>
        );
      })}
    </Carousel>
  );
};

// Common zoom button styling
const zoomButtonStyle = {
  color: '#fff',
  borderRadius: '50%',
  padding: '10px'
};

export default React.memo(CarousalItems);
