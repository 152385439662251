import { Done, DoneAll } from '@mui/icons-material';
import { Avatar, AvatarGroup, Badge, Box, Button, Chip, Divider, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Modal, Paper, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { colors } from 'styles/colors';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { AddEmojiReaction, BlockIcon, DeleteEmojiIcon, ThumbsUpIcon, GrinningSquintingFaceIcon, MaximizeChat, MinimizeChat, OpenMouthFaceIcon, PipeIcon, RedHeartIcon, ReportFriendIcon, SendMsgIcon, UnfriendIcon, StartConversationIcon, BlockAndReportIcon, MuteChatIcon } from 'assets/icon';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl } from 'utilities/userImageURL';
import { CheckGreenCircle, CloseRoundIcon, DeleteIcon, MoreDotIcon } from 'assets';
import EmojiPicker from 'emoji-picker-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPostBlockUnblock, deleteConversation, deleteMessage, getAllMessages, reportConversation, sendMessage, updateDeliveredStatus, updateMessageReaction, updateReadStatus } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { RootState } from 'store';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AdminIconButton from 'pages/components/iconButton';
import ConfirmationModal from 'pages/components/confirmationModal';
import DialogActionWall from 'pages/components/dialogActionWall';
import SelectDropdown from 'pages/components/selectDropdown';
import { ApiPaths } from 'models/apiPaths';
import ChatsSkeleton from '__mocks__/skeletonLoading/chatLoader';
import { clearMessages } from 'store/signalrSlice';

export interface IChatPopupProps {
    loggedUser: any;
    conversationId: string;
    friends: any[];
    onClose: (conversationId: string) => void;
    position: number;
}

export interface IMessage {
    id: string;
    senderId: string;
    participantIds: string[];
    content: string;
    reactions: Reaction[];
    sent: boolean;
    delivered: string[];
    read: string[];
    deletedBy: string[];
    createdAt: Date;
    modifiedAt: Date;
}

export interface IMessageRequest {
    senderId: string;
    participantIds: string[];
    content: string;
    sent: boolean;
    delivered: string[];
    read: string[];
    createdAt: Date;
}

interface Reaction {
    id: string;
    reaction: string;
    userId: string;
    createdAt: Date;
    modifiedAt?: Date;
}

export interface ISendMessageRequest {
    conversationId: string | null;
    message: IMessageRequest;
}

enum EmojiReaction {
    ThumbsUp = '👍',
    RedHeart = '❤️',
    GrinningSquintingFace = '😆',
    OpenMouthFace = '😮',
}

type ReactionIcon = {
    emoji: string;
    icon: any;
};

const reactionIcons: Record<EmojiReaction, ReactionIcon> = {
    [EmojiReaction.ThumbsUp]: { emoji: EmojiReaction.ThumbsUp, icon: <ThumbsUpIcon /> },
    [EmojiReaction.GrinningSquintingFace]: { emoji: EmojiReaction.GrinningSquintingFace, icon: <GrinningSquintingFaceIcon /> },
    [EmojiReaction.OpenMouthFace]: { emoji: EmojiReaction.OpenMouthFace, icon: <OpenMouthFaceIcon /> },
    [EmojiReaction.RedHeart]: { emoji: EmojiReaction.RedHeart, icon: <RedHeartIcon /> },
};

interface IOpenDialog {
    id?: string;
    open?: boolean;
    message?: string;
    label?: string;
    title?: string;
    userId?: string;
    conversationId?: string;
    username?: string;
    loaderBtn?: boolean;
    confirmTxt?: boolean;
    successModal?: boolean;
    successHandler?: () => void;
}

const ChatPopup: React.FC<IChatPopupProps> = ({ loggedUser, conversationId, friends, onClose, position }) => {
    const [minimized, setMinimized] = useState(false);
    const [friendsData, setFriendData] = useState<any[]>(friends);
    const [conversationData, setConversationData] = useState<any>();
    const [menuOpen, setMenuOpen] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [message, setMessage] = useState("");
    const [showEmojiPickerFor, setShowEmojiPickerFor] = useState<string>();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const navigate = useNavigate();
    const [groupedMessages, setGroupedMessages] = useState<Record<string, IMessage[]>>({});
    const MAX_CHAR_LIMIT = 1900;
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };
    const reason = [
        'Hate Speech',
        'Violence',
        'Inappropriate for public',
        'Nudity',
        'Other'
    ];
    const signalr = useAppSelector((state: RootState) => state.signalr);
    const scrollableBodyRef = useRef<HTMLDivElement | null>(null);
    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loader, setLoader] = useState<string>('');
    const [selectedReaction, setSelectedReaction] = useState<any[]>([]);
    const [reportDescription, setReportDescription] = useState<string>('');
    const [selectedReason, setSelectedReason] = useState<string>('');
    const [reportModal, setReportModal] = useState<IOpenDialog>({
        open: false,
        id: '',
        loaderBtn: false,
        successModal: false
    });
    const [blockConfirmModal, setBlockConfirmModal] = useState<IOpenDialog>();
    const [showDetailedEmojiWindow, setShowDetailedEmojiWindow] = useState(false);
    const [reactionsToShow, setReactionsToShow] = useState<any[]>([]);
    const [isSending, setIsSending] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(false);
    const emojiRef = useRef<HTMLDivElement | null>(null);
    const [selectedMessage, setSelectedMessage] = useState<IMessage>();
    const [cursorPosition, setCursorPosition] = useState<any>(0);
    const [isChatLoading, setIsChatLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const location = useLocation();
    let { messageId } = location.state || {};
    const [currentMessageId, setCurrentMessageId] = useState(messageId);
    const isInitialScrollComplete = useRef(false);
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedReason(event.target.value as string);
    };

    const handleClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const getAvatar = (senderId: string) => {
        let sender = friendsData.find(friend => friend.userId === senderId);

        if (!sender && loggedUser && loggedUser.id === senderId) {
            sender = {
                "userId": loggedUser.id,
                "username": loggedUser.username,
                "firstName": null,
                "lastName": null,
                "imageId": loggedUser.userImage.id,
                "onlineStatus": false
            }
        }

        return <AvatarImg
            id='userImg'
            enableLetterImg={!sender.imageId}
            alt={sender.username}
            src={getUrl(sender.imageId)}
            name={sender.username}
            width={20}
            height={20}
            style={{
                objectFit: 'cover',
                borderRadius: '50%',
                border: `2px solid ${colors.border.primary}`
            }}
        />
    }

    const getReactions = (reactions: any[], sliceNeeded: boolean) => {
        const reactionMap: Record<string, { count: number; recentTimestamp: string; reaction: string; users: string[] }> = reactions.reduce(
            (acc, reaction) => {
                const key = reaction.reaction;
                const timestamp = reaction.modifiedAt || reaction.createdAt;
                const userId = reaction.userId;

                if (!acc[key]) {
                    acc[key] = { count: 0, recentTimestamp: timestamp, reaction: key, users: [] };
                }

                acc[key].count += 1;
                acc[key].users.push(userId);

                acc[key].recentTimestamp =
                    new Date(timestamp) > new Date(acc[key].recentTimestamp) ? timestamp : acc[key].recentTimestamp;

                return acc;
            },
            {} as Record<string, { count: number; recentTimestamp: string; reaction: string; users: string[] }>
        );

        const topReactions = Object.values(reactionMap)
            .sort((a, b) => new Date(b.recentTimestamp).getTime() - new Date(a.recentTimestamp).getTime());

        return sliceNeeded ? topReactions.slice(0, 3) : topReactions;
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
                setShowEmojiPicker(false);
                setOpenEmoji(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiRef]);

    useEffect(() => {
        if (showDetailedEmojiWindow) {
            setSelectedReaction(getReactions(reactionsToShow, false));
        }
    }, [showDetailedEmojiWindow]);

    useEffect(() => {
        setFriendData((prevData) => {
            return prevData.map((friend) => {
                if (friend.userId === signalr.userStatus.userid) {
                    return {
                        ...friend,
                        onlineStatus: signalr.userStatus.isonline
                    };
                }
                return friend;
            });
        });
    }, [signalr.userStatus]);

    const getDisplayName = (friendsData: any[]) => {
        if (friendsData.length === 2) {
            let receivers = friendsData.filter(friend => friend.userId !== loggedUser.userId);
            return receivers[0].username;
        }
        // if (friendsData.length === 2) return `${friendsData[0].username}, ${friendsData[1].username}`;
        if (friendsData.length > 2) {
            return `${friendsData[0].username}, ${friendsData[1].username}, +${friendsData.length - 2}`;
        }
        return '';
    };

    const getActiveStatus = (friendsData: any[]) => {
        if (friendsData.length === 2) return friendsData[0].onlineStatus ? 'Active Now' : 'Offline';
        else return `${friendsData.length} members`;
    };

    const stringToColor = (string: string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    };

    const getInitials = (name: string) => {
        return name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase())
            .join('');
    };

    const renderAvatar = (friends: any[]) => {
        if (friends.length === 3) {
            return friends.map((friend, index) => (
                <Avatar
                    key={index}
                    alt={friend.username}
                    src={friend.imageId ? getUrl(friend.imageId) : undefined}
                    sx={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                        border: `2px solid ${colors.border.primary}`,
                        marginLeft: -35,
                        zIndex: index,
                        width: friends.length === 1 ? 40 : 35,
                        height: friends.length === 1 ? 40 : 35,
                        bgcolor: friend.imageId ? undefined : stringToColor(friend.username),
                    }}
                >
                    {!friend.imageId && getInitials(friend.username)}
                </Avatar>
            ))
        }
        else {
            return <>
                <Avatar
                    sx={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                        border: `2px solid ${colors.border.primary}`,
                        backgroundColor: colors.text.primaryTheme,
                        width: 30,
                        height: 30,
                        zIndex: 3,
                        fontSize: 14,
                    }}
                >
                    +{friends.length - 2}
                </Avatar>
                <Avatar
                    alt={friends[1].username}
                    src={friends[1].imageId ? getUrl(friends[1].imageId) : undefined}
                    sx={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                        border: `2px solid ${colors.border.primary}`,
                        width: 30,
                        height: 30,
                        zIndex: 2,
                        bgcolor: friends[1].imageId ? undefined : stringToColor(friends[1].username),
                    }}
                >
                    {!friends[1].imageId && getInitials(friends[1].username)}
                </Avatar>
                <Avatar
                    alt={friends[0].username}
                    src={friends[0].imageId ? getUrl(friends[0].imageId) : undefined}
                    sx={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                        border: `2px solid ${colors.border.primary}`,
                        width: 30,
                        height: 30,
                        zIndex: 1,
                        bgcolor: friends[0].imageId ? undefined : stringToColor(friends[0].username),
                    }}
                >
                    {!friends[0].imageId && getInitials(friends[0].username)}
                </Avatar></>
        }
    };

    const getAvatars = (friends: any[]) => {

        let friend;
        if (friends.length === 2) {
            friend = friends.find(friend => friend.userId !== loggedUser.id);
        }

        return (
            friends.length === 2 ?
                (<Badge
                    sx={{
                        '& .MuiBadge-dot': {
                            borderRadius: '50%',
                            width: friend?.onlineStatus ? 10 : 0,
                            height: friend?.onlineStatus ? 10 : 0,
                            border: friend?.onlineStatus ? `1px solid ${colors.text.primaryWhite}` : '',
                            backgroundColor: '#4caf50',
                        },
                    }}
                    variant="dot"
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {<Avatar
                        alt={friend.username}
                        src={friend.imageId ? getUrl(friend.imageId) : undefined}
                        sx={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                            border: `2px solid ${colors.border.primary}`,
                            zIndex: 1,
                            width: 30,
                            height: 30,
                            bgcolor: friend.imageId ? undefined : stringToColor(friend.username),
                        }}
                    >
                        {!friend.imageId && getInitials(friend.username)}
                    </Avatar>}
                </Badge>) :
                <AvatarGroup max={3} spacing={30}>
                    {renderAvatar(friends)}
                </AvatarGroup>
        );
    };

    const getConversation = () => {
        setIsChatLoading(true);
        getAllMessages(conversationId)
            .then((data) => {
                const filteredMessages = data.conversation.messages.filter((message: any) => {
                    return !message.deletedBy.includes(loggedUser.id);
                });
                setMessages(filteredMessages);
                setConversationData(data);
            }
            ).catch((err) => {
                console.error("Error fetching conversations:", err);
            })
            .finally(() => {
                updateReadStatus(conversationId);
            });
    };

    const handleMessageUpdate = (input: string, isEmoji = false) => {
        let newMessage = message;

        if (isEmoji) {
            const start = message.slice(0, cursorPosition);
            const end = message.slice(cursorPosition);
            newMessage = start + input + end;

            if (message.length <= MAX_CHAR_LIMIT) {
                setMessage(newMessage);
                setCursorPosition(cursorPosition + input.length);
            }
        } else {
            if (input.length <= MAX_CHAR_LIMIT) {
                newMessage = input;
            } else {
                newMessage = input.slice(0, MAX_CHAR_LIMIT);
            }
            setMessage(newMessage);
        }
    };

    const handleSendMessage = async () => {
        if (isSending) return;

        setIsSending(true);

        const inputMessage: IMessageRequest = {
            content: message,
            delivered: [],
            read: [],
            sent: true,
            participantIds: friendsData
                .filter(friend => friend.userId !== loggedUser.id)
                .map(friend => friend.userId),
            senderId: loggedUser.id,
            createdAt: new Date()
        }
        const messageRequest: ISendMessageRequest = {
            conversationId: conversationId,
            message: inputMessage
        }
        try {
            const res = await sendMessage(messageRequest);
            if (!res.isBlocked && ((friends.length > 2) || (friends.length === 2 && res.isFriend)) && !res.isBlockedByOther) {
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages, res.message];
                    setGroupedMessages(groupMessagesByDate(updatedMessages));
                    return updatedMessages;
                });
            }
            else {
                conversationData.isFriend = res.isFriend;
                conversationData.isBlockedByOther = res.isBlockedByOther;
                conversationData.isBlocked = res.isBlocked;
                setConversationData(conversationData);
            }

            setMessage("");
            setTimeout(scrollToBottom, 100);
        } finally {
            setIsSending(false);
        }
    }

    const handleAddReaction = async (messageId: string, emoji: string) => {

        const payload = {
            conversationId: conversationId,
            messageId: messageId,
            reaction: {
                reaction: emoji,
                userid: loggedUser.id
            },
        };

        var res = await updateMessageReaction(payload);
        setCurrentMessageId(messageId);
        isInitialScrollComplete.current = false;
        if (!res.isBlocked && ((friends.length > 2) || (friends.length === 2 && res.isFriend)) && !res.isBlockedByOther) {
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === messageId ? res.message : message
                )
            );
        }
        else {
            conversationData.isFriend = res.isFriend;
            conversationData.isBlockedByOther = res.isBlockedByOther;
            conversationData.isBlocked = res.isBlocked;
            setConversationData(conversationData);
        }
    };

    const handleDeleteConversation = async (conversationId: string) => {
        await deleteConversation(conversationId).then(() => onClose(conversationId));
    }

    const onCancelDelete = () => {
        setIsDeleteImage(false);
    };

    const handleConversationDelete = (conversationId: string) => {
        setIsDeleteImage(true);
    };

    const handleDeleteMessage = async (messageId: string) => {
        await deleteMessage(conversationId, messageId);
        setCurrentMessageId(messageId);
        isInitialScrollComplete.current = false;
        setMessages((prevMessages) =>
            prevMessages.filter((message) => message.id !== messageId)
        );
    }

    const groupMessagesByDate = (messages: IMessage[]) => {
        try {
            return messages.reduce((groups, message) => {
                const date = new Date(message.createdAt).toDateString();
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(message);
                return groups;
            }, {} as Record<string, IMessage[]>);
        }
        finally {
            setIsChatLoading(false);
        }
    };

    const getSenderFullName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (!sender && loggedUser && loggedUser.id === senderId) {
            sender = loggedUser;
        }

        if (sender) {
            return sender.username;
        }
        return '';
    }

    const getSenderName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (sender) {
            return (
                <TypographyWithI18N
                    i18nKey={sender.username.split(' ')[0]}
                    fontSize={12}
                    sx={{
                        marginLeft: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
            );
        }
        return '';
    }

    const getRecipientName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (sender) {
            return sender.username;
        }
        return '';
    }

    const saveReportHandler = async () => {
        setReportModal((pv) => ({
            ...pv,
            loaderBtn: true
        }));
        setLoader(`account-menu_${reportModal.id}`);
        const payload = {
            conversationId: reportModal.id,
            reason: selectedReason,
            description: reportDescription
        };
        try {
            const response = await reportConversation(payload);
            if (!response.error) {
                setReportModal((pv) => ({
                    ...pv,
                    loaderBtn: false,
                    successModal: true
                }));
            }
        } catch (error: unknown) {
        } finally {
            setLoader('');
            getConversation();
        }
    };

    const blockUnblockHandler = async (blockedUserId: any) => {
        console.log(blockConfirmModal);
        const payload = {
            blockedUserId: blockedUserId
        };
        setBlockConfirmModal((pv) => ({
            ...pv,
            open: true,
            loaderBtn: true
        }));

        try {
            const apiPath = conversationData.isBlocked
                ? ApiPaths.USER_UN_BLOCK
                : ApiPaths.USER_BLOCK;
            await axiosPostBlockUnblock(apiPath, payload);
        } catch (error: unknown) {
            console.log(error);
        } finally {
            setBlockConfirmModal((pv) => ({
                ...pv,
                open: true,
                loaderBtn: false,
                message: `You have successfully ${conversationData.isBlocked ? 'unblocked' : 'blocked'} ${getRecipientName(blockedUserId)}.`,
                confirmTxt: false
            }));
            getConversation();
        }
    };

    const formatDate = (dateString: string): string => {
        const messageDate = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const isToday = messageDate.toDateString() === today.toDateString();
        const isYesterday = messageDate.toDateString() === yesterday.toDateString();

        if (isToday) return "Today";
        if (isYesterday) return "Yesterday";

        const daysDifference = Math.floor((today.getTime() - messageDate.getTime()) / (1000 * 60 * 60 * 24));
        if (daysDifference < 7) {
            return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(messageDate);
        }

        if (messageDate.getFullYear() === today.getFullYear()) {
            return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric' }).format(messageDate);
        }

        return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }).format(messageDate);
    };

    const handleIncomingMessage = (newMessage: any) => {
        const newConversationId = newMessage.conversationId;
        if (newConversationId === conversationId) {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, newMessage.message];
                return updatedMessages;
            });
            dispatch(clearMessages());
            updateDeliveredStatus().then().finally(() => {
                updateReadStatus(conversationId);
            });
        }
    }

    const handleUpdatedMessage = (newMessage: any) => {
        const newConversationId = newMessage.conversationId;
        if (newConversationId === conversationId) {
            setMessages((prevMessages) => {
                const updatedMessages = prevMessages.map((msg) =>
                    msg.id === newMessage.message.id ? newMessage.message : msg
                );
                return updatedMessages;
            });
        }
    }

    useEffect(() => {
        if (signalr.messages && !(Array.isArray(signalr.messages) && signalr.messages.length === 0)) {
            handleIncomingMessage(signalr.messages);
        }
    }, [signalr.messages]);

    useEffect(() => {
        if (messages.length > 0) {
            handleUpdatedMessage(signalr.deliveredMessages);
        }

    }, [signalr.deliveredMessages]);

    useEffect(() => {
        setGroupedMessages(groupMessagesByDate(messages));
    }, [messages]);

    useEffect(() => {
        conversationId.length > 0 && getConversation();
    }, []);

    const scrollToBottom = () => {
        if (scrollableBodyRef.current) {
            scrollableBodyRef.current.scrollTop = scrollableBodyRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (currentMessageId && !isInitialScrollComplete.current) {
            const element = document.getElementById(`${currentMessageId}`);
            if (element) {
                element.scrollIntoView({ block: 'center' });
                isInitialScrollComplete.current = true;
            }
        } else if (!currentMessageId) {
            scrollToBottom();
        }
    }, [currentMessageId, groupedMessages]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                right: -position * 410,
                width: 400,
                height: minimized ? 'auto' : 500,
                boxShadow: 3,
                borderRadius: '8px 8px 0 0',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 1300 + position
            }}
        >
            {isChatLoading ? <ChatsSkeleton showSubLine={1} showChatSkeleton={5} /> :
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, color: colors.text.primaryBlack }}>
                        <Box sx={{ display: 'flex', marginLeft: 1 }} onClick={() => {
                            if (friends.length === 2 && friends[0]?.userId) {
                                navigate(`/profile/${friends[0].userId}`);
                            }
                        }}>
                            {getAvatars(friendsData)}
                            <Box sx={{ marginLeft: 2, flexGrow: 1, cursor: 'pointer' }}>
                                <TypographyWithI18N
                                    className='bodyTextSmallBold'
                                    i18nKey={getDisplayName(friendsData)}
                                    sx={{
                                        color: colors.text.primaryBlack,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: 100,
                                        textOverflow: 'ellipsis',
                                    }} />
                                <TypographyWithI18N
                                    i18nKey={getActiveStatus(friendsData)}
                                    onMouseEnter={friends.length > 2 ? (event) => handleClick('members')(event) : undefined}
                                    fontSize={12}
                                    sx={{
                                        color: colors.text.approxSuvaGrey,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: 100,
                                        textOverflow: 'ellipsis',
                                    }} />
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openElem === 'members'}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: 200
                                        }
                                    }}
                                    sx={{
                                        '& .MuiMenuItem-root': {
                                            minHeight: 30
                                        }
                                    }}
                                >
                                    {
                                        friends.map((friend, index) => {
                                            return <MenuItem key={index}
                                                onClick={() => navigate(`/profile/${friend.userId}`)}
                                            >
                                                <AvatarImg
                                                    id="userImg"
                                                    enableLetterImg={!friend?.imageId}
                                                    alt={friend.username}
                                                    src={getUrl(friend?.imageId)}
                                                    name={friend?.username}
                                                    nameStyle={{
                                                        fontSize: 10,
                                                    }}
                                                    width={20}
                                                    height={20}
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                                <TypographyWithI18N
                                                    className='bodyTextSmall ellipsisText'
                                                    i18nKey={friend.username}
                                                    color={colors.text.primaryBlack}
                                                    maxWidth={200}
                                                    sx={{
                                                        marginLeft: 1
                                                    }}
                                                />
                                            </MenuItem>
                                        })
                                    }
                                </Menu>
                            </Box>
                        </Box>
                        <Box>
                            <AdminIconButton
                                id='settings'
                                aria-label='more'
                                icon={<MoreDotIcon
                                    style={{ width: 20, height: 20 }} />}
                                onClick={handleClick('submenu')}
                            />
                            <Menu
                                id={`account-menu`}
                                anchorEl={anchorEl}
                                open={openElem === 'submenu'}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                PaperProps={{
                                    style: {
                                        width: 150
                                    }
                                }}
                                sx={{
                                    '& .MuiMenuItem-root': {
                                        minHeight: 20
                                    }
                                }}
                            >
                                {friends.length === 2 && [
                                    <MenuItem
                                        key="block"
                                        onClick={() => {
                                            handleClose();
                                            let receiver = friends.find(friend => friend.userId !== loggedUser.userId);
                                            setBlockConfirmModal((pv) => ({
                                                ...pv,
                                                open: true,
                                                id: conversationId,
                                                username: receiver.username,
                                                message: `Are you sure you want to ${conversationData.isBlocked ? 'unblock' : 'block'} ${receiver.username}?`,
                                                label: conversationData.isBlocked ? 'Unblock' : 'Block',
                                                title: conversationData.isBlocked ? 'Unblock User' : 'Block User',
                                                confirmTxt: true,
                                                successHandler: () => {
                                                    blockUnblockHandler(receiver.userId);
                                                }
                                            }));
                                        }}
                                        sx={{ height: 25 }}
                                    >
                                        <ListItemIcon>
                                            <BlockIcon
                                                style={{ width: 20, height: 18 }}
                                            />
                                        </ListItemIcon>
                                        <TypographyWithI18N
                                            className='bodyText'
                                            i18nKey={conversationData?.isBlocked ? 'Unblock' : `Block`}
                                            style={{ fontSize: 14 }}
                                            color={colors.text.primaryBlack}
                                        />
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key="report"
                                        onClick={() => {
                                            handleClose();
                                            setReportModal((pv) => ({
                                                ...pv,
                                                open: true,
                                                id: conversationId,
                                            }));
                                        }}
                                        disabled={conversationData?.isReported}
                                        sx={{ height: 25 }}
                                    >
                                        <ListItemIcon>
                                            <BlockAndReportIcon
                                                style={{ width: 20, height: 18 }}
                                            />
                                        </ListItemIcon>
                                        <TypographyWithI18N
                                            className='bodyText'
                                            i18nKey={`Report`}
                                            style={{ fontSize: 14 }}
                                            color={colors.text.primaryBlack}
                                        />
                                    </MenuItem>,
                                    <Divider />
                                ]}

                                <MenuItem
                                    onClick={() => {
                                        handleConversationDelete(conversationId);
                                    }}
                                    sx={{ height: 25 }}
                                >
                                    <ListItemIcon>
                                        <DeleteIcon
                                            style={{ width: 20, height: 18 }} />
                                    </ListItemIcon>
                                    <TypographyWithI18N
                                        className='bodyText'
                                        i18nKey={`Delete Chat`}
                                        style={{ fontSize: 14 }}
                                        color={colors.text.primaryBlack}
                                    />
                                </MenuItem>
                            </Menu>
                            <AdminIconButton
                                id='minimize'
                                aria-label='minormax'
                                icon={minimized ?
                                    <MaximizeChat style={{ width: 20, height: 20 }} /> :
                                    <MinimizeChat style={{ width: 20, height: 20 }} />}
                                onClick={() => setMinimized(!minimized)}
                            />
                            <AdminIconButton
                                id='close'
                                aria-label='close'
                                icon={<CloseRoundIcon
                                    style={{ width: 20, height: 20 }} />}
                                onClick={() => onClose(conversationId)}
                            />
                        </Box>
                    </Box>
                    {menuOpen && (
                        <Box
                            ref={menuRef}
                            sx={{
                                position: 'absolute',
                                left: -210,
                                width: 200,
                                bgcolor: 'background.paper',
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            <MenuItem onClick={() => { alert('Block'); handleMenuToggle(); }}><IconButton><BlockIcon /></IconButton> Block</MenuItem>
                            <MenuItem onClick={() => { alert('Report'); handleMenuToggle(); }}><IconButton><ReportFriendIcon /></IconButton> Block and Report</MenuItem>
                            <MenuItem onClick={() => { alert('Report'); handleMenuToggle(); }}><IconButton><UnfriendIcon /></IconButton> Report</MenuItem>
                            <MenuItem onClick={() => { alert('Delete Chat'); handleMenuToggle(); }}><IconButton><UnfriendIcon /></IconButton> Delete Chat</MenuItem>
                        </Box>
                    )}
                    {!minimized && (
                        <Box sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'auto',
                            padding: [2, 2, 2, 2]

                        }}
                            alignItems={Object.keys(groupedMessages).length === 0 ? 'center' : ''}
                            justifyContent={Object.keys(groupedMessages).length === 0 ? 'center' : ''}
                            ref={scrollableBodyRef}>
                            {Object.keys(groupedMessages).length === 0 ? (
                                <>
                                    <StartConversationIcon />
                                    <TypographyWithI18N
                                        className='smallHeader'
                                        textAlign={'center'}
                                        i18nKey={'Start Conversation !'}
                                    />
                                </>
                            ) : (
                                <>
                                    {Object.entries(groupedMessages).map(([date, messages]) => (
                                        <Box key={date}>
                                            <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', marginBottom: 1 }}>
                                                {formatDate(date)}
                                            </Typography>
                                            {messages.map((message, index) => {
                                                const isLastInGroup = index === messages.length - 1 || message.senderId !== messages[index + 1]?.senderId;
                                                const hasReactions = message.reactions != null && message.reactions.length > 0;
                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            justifyContent: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                            marginBottom: hasReactions ? 4 : 1,
                                                            position: 'relative'
                                                        }}
                                                        onMouseLeave={() => setShowEmojiPickerFor("")}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    padding: '6px',
                                                                    backgroundColor: message.senderId === loggedUser.id ? '#208d92' : '#f5f5f5',
                                                                    color: message.senderId === loggedUser.id ? 'white' : 'black',
                                                                    borderRadius: message.senderId === loggedUser.id ? '20px 20px 0 20px' : '20px 20px 20px 0',
                                                                    minWidth: '50%',
                                                                    position: 'relative',
                                                                }}
                                                                onMouseEnter={() => setShowEmojiPickerFor(message.id)}
                                                            >
                                                                <Typography
                                                                    fontSize={14}
                                                                    sx={{
                                                                        margin: '2px 5px 2px 5px',
                                                                        wordBreak: 'break-word',
                                                                        whiteSpace: 'pre-line'
                                                                    }}
                                                                    variant="body2"
                                                                >
                                                                    {message.content}
                                                                </Typography>
                                                                {message.reactions != null && message.reactions.length > 0 && (
                                                                    message.reactions.length === 1 ? (
                                                                        <Box
                                                                            onClick={(event) => { event.stopPropagation(); setShowDetailedEmojiWindow(true); setReactionsToShow(message.reactions) }}
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                left: message.senderId !== loggedUser.id ? 'auto' : 0,
                                                                                right: message.senderId !== loggedUser.id ? 0 : 'auto',
                                                                                backgroundColor: colors.background.primaryBackground,
                                                                                border: `2px solid ${colors.background.primaryTab}`,
                                                                                fontSize: 14,
                                                                                padding: '2px',
                                                                                borderRadius: '14px',
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    backgroundColor: colors.background.primaryBackground,
                                                                                },
                                                                            }}>
                                                                            {getReactions(message.reactions, true).map((reaction) => (reaction.reaction))}
                                                                        </Box>
                                                                    ) :
                                                                        (<Chip
                                                                            onClick={(event) => { event.stopPropagation(); setShowDetailedEmojiWindow(true); setReactionsToShow(message.reactions) }}
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                left: message.senderId !== loggedUser.id ? 'auto' : 0,
                                                                                right: message.senderId !== loggedUser.id ? 0 : 'auto',
                                                                                backgroundColor: colors.background.primaryBackground,
                                                                                border: `2px solid ${colors.background.primaryTab}`,
                                                                                height: 25,
                                                                                fontSize: 14,
                                                                                '&:hover': {
                                                                                    backgroundColor: colors.background.primaryBackground,
                                                                                },
                                                                            }}
                                                                            label={getReactions(message.reactions, true).map((reaction) => (reaction.reaction))}
                                                                        />)
                                                                )}
                                                            </Box>
                                                            {isLastInGroup && (
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                                        marginBottom: 1,
                                                                        marginTop: 1,
                                                                    }}
                                                                >
                                                                    {message.senderId === loggedUser.id ? (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            >{friends.length === 2 && (message.participantIds.every(id => message.read.includes(id)) ? (
                                                                                <DoneAll color="primary"
                                                                                    style={{ width: 20, height: 20 }} />
                                                                            ) : message.participantIds.every(id => message.delivered.includes(id)) ? (
                                                                                <DoneAll style={{ width: 20, height: 20 }} />
                                                                            ) : (
                                                                                <Done style={{ width: 20, height: 20 }} />
                                                                            ))}
                                                                            </Box>
                                                                            <TypographyWithI18N
                                                                                i18nKey={new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    hour12: true,
                                                                                }).toLowerCase()}
                                                                                fontSize={10}
                                                                                sx={{
                                                                                    color: colors.text.approxSuvaGrey,
                                                                                    margin: '5px 15px 5px 10px',
                                                                                }}
                                                                            />
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                                <AvatarImg
                                                                                    id="userImg"
                                                                                    enableLetterImg={!loggedUser?.userImage?.id}
                                                                                    alt={loggedUser.username}
                                                                                    src={getUrl(loggedUser?.userImage?.id)}
                                                                                    name={loggedUser.username}
                                                                                    nameStyle={{
                                                                                        fontSize: 10,
                                                                                    }}
                                                                                    width={20}
                                                                                    height={20}
                                                                                    style={{
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </>) : (
                                                                        <>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}>
                                                                                {getAvatar(message.senderId)}
                                                                            </Box>
                                                                            {friends.length > 2 && <Box>
                                                                                {getSenderName(message.senderId)}
                                                                            </Box>}
                                                                            <TypographyWithI18N
                                                                                i18nKey={new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    hour12: true,
                                                                                }).toLowerCase()}
                                                                                fontSize={10}
                                                                                sx={{
                                                                                    color: colors.text.approxSuvaGrey,
                                                                                    margin: '5px 15px 5px 10px',
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            )}
                                                            {showEmojiPickerFor === message.id && (
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        bottom: '100%',
                                                                        right: message.senderId === loggedUser.id ? 0 : 'unset',
                                                                        left: message.senderId !== loggedUser.id ? 0 : 'unset',
                                                                        zIndex: 1500,
                                                                        backgroundColor: colors.background.white,
                                                                        borderRadius: '8px',
                                                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                                        padding: '5px 10px',
                                                                        display: 'flex',
                                                                        gap: '10px',
                                                                    }}
                                                                >
                                                                    {!(conversationData?.isBlockedByOther || conversationData?.isBlocked) && ((friends.length > 2) || (friends.length === 2 && conversationData?.isFriend)) && Object.values(EmojiReaction).map((reaction: EmojiReaction) => {
                                                                        const { emoji, icon: Icon } = reactionIcons[reaction];
                                                                        return (
                                                                            <Box
                                                                                key={reaction}
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '24px',
                                                                                    '&:hover': {
                                                                                        transform: 'scale(1.2)',
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleAddReaction(message.id, reaction)}
                                                                            >
                                                                                {
                                                                                    Icon
                                                                                }
                                                                            </Box>
                                                                        );
                                                                    })}
                                                                    {!(conversationData?.isBlockedByOther || conversationData?.isBlocked) && ((friends.length > 2) || (friends.length === 2 && conversationData?.isFriend)) && <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            fontSize: '24px',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => { setOpenEmoji(true); setSelectedMessage(message); setShowEmojiPicker(prev => !prev) }}
                                                                    >
                                                                        <AddEmojiReaction />
                                                                    </Box>}
                                                                    {!(conversationData?.isBlockedByOther || conversationData?.isBlocked) && ((friends.length > 2) || (friends.length === 2 && conversationData?.isFriend)) && <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            fontSize: '24px',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleDeleteMessage(message.id)}
                                                                    >
                                                                        <PipeIcon />
                                                                    </Box>}
                                                                    <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            fontSize: '24px',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleDeleteMessage(message.id)}
                                                                    >
                                                                        <DeleteEmojiIcon />
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>)
                                            }
                                            )}
                                        </Box>
                                    ))}
                                </>)}
                        </Box>
                    )}
                    {showEmojiPicker && (
                        <Box
                            ref={emojiRef}
                            sx={{
                                position: 'absolute',
                                bottom: '60px',
                                right: 0,
                                zIndex: 1500,
                            }}>
                            <EmojiPicker
                                onEmojiClick={(emoji) => {
                                    if (openEmoji) {
                                        handleAddReaction(selectedMessage!.id, emoji.emoji);
                                        setShowEmojiPicker(false);
                                    }
                                    else {
                                        handleMessageUpdate(emoji.emoji, true)
                                        setTimeout(() => {
                                            const input = document.getElementById("messageTextField");
                                            if (input) {
                                                input.scrollTop = input.scrollHeight;
                                            }
                                        }, 0);
                                    }
                                }}
                                previewConfig={{ showPreview: false }}
                            />
                        </Box>
                    )}
                    {!minimized && (
                        <Box sx={{ bottom: 0, backgroundColor: 'white' }}>
                            <Divider />
                            <Box sx={{ display: 'flex' }} justifyContent='center'>
                                {(conversationData?.isBlockedByOther || conversationData?.isBlocked || (friends.length === 2 && !conversationData?.isFriend)) ?
                                    <TypographyWithI18N
                                        padding={2}
                                        className='bodyText'
                                        color={colors.text.darkGray}
                                        i18nKey={
                                            (friends.length === 2 && !conversationData?.isFriend) ? `Send friend request to ${getDisplayName(friendsData)} to resume the conversation.` : '4um member unable to receive messages.'
                                        }
                                    /> : <TextField
                                        id="messageTextField"
                                        placeholder="Type Your Message..."
                                        multiline
                                        rows={2}
                                        disabled={isSending}
                                        error={message.length === 1900}
                                        helperText={message.length === 1900 ? "Input Limit Reached" : null}
                                        size='small'
                                        autoComplete='off'
                                        value={message}
                                        onChange={(e) => {
                                            setCursorPosition(e.target.selectionStart);
                                            handleMessageUpdate(e.target.value);
                                        }}
                                        inputRef={(input) => {
                                            if (input) {
                                                input.selectionStart = cursorPosition; // Set cursor back after update
                                                input.selectionEnd = cursorPosition;
                                            }
                                        }}
                                        onClick={(e) => {
                                            const target = e.target as HTMLTextAreaElement;
                                            if (target.selectionStart) {
                                                setCursorPosition(target.selectionStart);
                                            }

                                        }}
                                        onKeyUp={(e) => {
                                            const target = e.target as HTMLTextAreaElement;
                                            setCursorPosition(target.selectionStart);
                                        }}
                                        variant="filled"
                                        sx={{
                                            padding: '5px',
                                            '& .MuiFilledInput-root': {
                                                padding: 1
                                            },
                                            width: '-webkit-fill-available'
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{
                                                    height: '3em',
                                                    maxHeight: '5em',
                                                    alignItems: 'end'
                                                }}>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => setShowEmojiPicker(prev => !prev)}
                                                    >
                                                        <InsertEmoticonIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey && message.trimEnd() != '') {
                                                e.preventDefault();
                                                handleSendMessage();
                                            } else if (e.key === 'Enter' && e.shiftKey) {
                                                e.preventDefault();
                                                const target = e.target as HTMLTextAreaElement;
                                                const start = target.selectionStart;
                                                const end = target.selectionEnd;
                                                setMessage((prev) => prev.slice(0, start) + '\n' + prev.slice(end));
                                                setTimeout(() => {
                                                    target.setSelectionRange(start + 1, start + 1);
                                                }, 0);
                                            }
                                        }}
                                    />}
                                {!(conversationData?.isBlockedByOther || conversationData?.isBlocked || (friends.length === 2 && !conversationData?.isFriend)) && message.trimEnd() != '' && <IconButton onClick={handleSendMessage}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}>
                                    <SendMsgIcon />
                                </IconButton>}
                            </Box>
                        </Box>
                    )}
                    {
                        <DialogActionWall
                            open={isDeleteImage}
                            title={`Are you sure you want to delete the conversation?`}
                            onClose={onCancelDelete}
                            headerContent={
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <DeleteEmojiIcon
                                        style={{ width: '48px', height: '48px', marginRight: '8px' }}
                                    />
                                </Box>
                            }
                            dialogTitleSxProps={{
                                textAlign: 'center',
                                mb: 0
                            }}
                            dialogActionsSxProps={{
                                justifyContent: 'center'
                            }}
                            dialogPaperSxProps={{
                                maxWidth: 513,
                                width: 513
                            }}
                        >
                            <TypographyWithI18N
                                textAlign={'center'}
                                sx={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem' }}
                                i18nKey={`This action cannot be undone.`}
                            />
                            <Box textAlign={'center'} mt={2}>
                                <Button
                                    onClick={() => { handleDeleteConversation(conversationId) }}
                                    style={{
                                        color: colors.error.primaryRed,
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={onCancelDelete}
                                    style={{
                                        color: colors.text.primaryBlack,
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </DialogActionWall>
                    }
                    {reportModal.open && (
                        <DialogActionWall
                            open={reportModal.open}
                            title={!reportModal.successModal ? `Report Details` : ''}
                            dialogTitleSxProps={{
                                textAlign: 'center'
                            }}
                            dialogActionsSxProps={{
                                justifyContent: 'center'
                            }}
                            closeIconButton
                            onClose={() =>
                                setReportModal((pv) => ({
                                    ...pv,
                                    open: false,
                                    successModal: false
                                }))
                            }
                            loaderBtn={reportModal.loaderBtn}
                            onSuccess={saveReportHandler}
                            successLabel={'Send'}
                            hideBtn={!reportModal.successModal}
                            successDisabled={!selectedReason}
                            dialogSuccessSxProps={{
                                opacity: reportModal.successModal
                                    ? 0
                                    : reportModal.loaderBtn
                                        ? 0.6
                                        : 1,
                                pointerEvents: reportModal.successModal ? 'none' : ''
                            }}
                        >
                            {reportModal.successModal ? (
                                <Box className='flexCenterJustify flexCenterColumn'>
                                    <CheckGreenCircle />
                                    <TypographyWithI18N
                                        mt={3}
                                        className='headerBold'
                                        color={colors.text.primaryBlack}
                                        i18nKey={
                                            'Your report has been submitted. Our Admin will review and take appropriate action.'
                                        }
                                    />
                                </Box>
                            ) : (
                                <>
                                    <SelectDropdown
                                        options={reason}
                                        value={selectedReason}
                                        onChangeEvent={handleChange}
                                        selectSx={{
                                            height: 42,
                                            mb: 2,
                                            color: colors.text.darkBlack
                                        }}
                                    />
                                    <TextField
                                        placeholder='Write description'
                                        multiline
                                        rows={4}
                                        fullWidth
                                        sx={{
                                            borderRadius: 0
                                        }}
                                        onChange={(event) => {
                                            setReportDescription(event.target.value);
                                        }}
                                        inputProps={{
                                            maxLength: 1000
                                        }}
                                    />
                                </>
                            )}
                        </DialogActionWall>
                    )}
                    {blockConfirmModal?.open && (
                        <ConfirmationModal
                            open={blockConfirmModal.open}
                            message={blockConfirmModal.message}
                            title={
                                blockConfirmModal.confirmTxt
                                    ? blockConfirmModal.title
                                        ? blockConfirmModal.title
                                        : blockConfirmModal.label
                                    : ''
                            }
                            successLabel={blockConfirmModal.label}
                            loaderBtn={blockConfirmModal.loaderBtn}
                            confirmTxt={blockConfirmModal.confirmTxt}
                            onSuccess={blockConfirmModal.successHandler}
                            onExit={() =>
                                setBlockConfirmModal((pv) => ({
                                    ...pv,
                                    open: false
                                }))
                            }
                        />
                    )}
                    <Modal
                        open={showDetailedEmojiWindow}
                        onClose={() => { setShowDetailedEmojiWindow(false); setSelectedReaction([]); }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Box
                            sx={{
                                width: '40%',
                                maxWidth: '400px',
                                backgroundColor: 'white',
                                padding: 3,
                                borderRadius: '8px',
                                boxShadow: 24,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                overflowY: 'auto',
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                                <Chip
                                    label={'All ' + `(${reactionsToShow.length})`}
                                    // color={(selectedReaction.length > 1) ? "primary" : undefined}
                                    sx={{
                                        backgroundColor: selectedReaction.length > 1 ? 'lightgray' : 'white',
                                        color: 'black',
                                        '&:hover': { backgroundColor: 'lightgray' }
                                    }}
                                    onClick={() => setSelectedReaction(getReactions(reactionsToShow, false))} />
                                {getReactions(reactionsToShow, false).map((reaction, index) => (
                                    <Chip
                                        key={index}
                                        // color={(selectedReaction.length === 1 && selectedReaction[0]?.reaction === reaction.reaction) ? "primary" : undefined}
                                        sx={{
                                            backgroundColor: (selectedReaction.length === 1 && selectedReaction[0]?.reaction === reaction.reaction) ? 'lightgray' : 'white',
                                            color: 'black',
                                            '&:hover': { backgroundColor: 'lightgray' }
                                        }}
                                        label={reaction.reaction + (reaction.count)}
                                        onClick={() => setSelectedReaction([reaction])} />
                                ))}
                            </Box>
                            {selectedReaction && (
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', }}>
                                        {selectedReaction.map((reaction) => reaction.users.map((userId: string, index: number) => (
                                            <>
                                                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                    <Box key={index} display='flex' flexDirection='row' alignItems='center'>
                                                        {getAvatar(userId)}
                                                        <TypographyWithI18N
                                                            i18nKey={getSenderFullName(userId)}
                                                            fontSize={12}
                                                            color={'black'}
                                                            sx={{
                                                                marginLeft: 1,
                                                                alignContent: 'center'
                                                            }}
                                                        />
                                                    </Box>
                                                    {reaction.reaction}
                                                </Box>
                                                <Divider />
                                            </>
                                        )))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Modal>
                </>}
        </Paper>
    );
};

export default ChatPopup;