import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupNameData: null
};

const utilsSlice = createSlice({
  name: 'utilsData',
  initialState,
  reducers: {
    setGroupNameData: (state, action) => {
      state.groupNameData = action.payload.groupNameData;
    }
  }
});

export const { setGroupNameData } = utilsSlice.actions;

export default utilsSlice.reducer;
